body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.swal2-container {
  z-index: 9999 !important;
}

nav.nav-tabs {
  display: flex;
  /*justify-content: center;*/
  align-items: center;
  flex-wrap: wrap;
  padding: 10px;
}


/*LEAFLET CONTROLS*/
.leaflet-control {
  border: none !important;
}

.easy-button-button {
  border-radius: 15px !important;
  border-color: transparent !important;
}

.easy-button-button:hover {
  font-size: 20px !important;
  color: #3a784d !important;
  border-color: #3a784d !important;
}

.leaflet-touch .leaflet-bar a:first-child {
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
}

.leaflet-touch .leaflet-bar a:last-child {
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}
