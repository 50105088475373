.scrollbar {
    overflow-y: scroll;
}
.scrollbar-primary {
    scrollbar-color: #2b7943 transparent;
}
.scrollbar-primary::-webkit-scrollbar {
    width: 12px;
    background-color: transparent; }

.scrollbar-primary::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #2b7943; }

.scrollbar-mida-light {
    scrollbar-color: #2b7943 transparent;
}
.scrollbar-mida-light::-webkit-scrollbar {
    width: 12px;
    background-color: transparent; }

.scrollbar-mida-light::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #B2DFDB;
}

.scrollbar-danger {
    scrollbar-color: #ff3547 #f5f5f5;
}

.scrollbar-danger::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #F5F5F5;
    border-radius: 10px; }

.scrollbar-danger::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5; }

.scrollbar-danger::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #ff3547; }

.scrollbar-warning {
    scrollbar-color: #ff8800 #f5f5f5;
}

.scrollbar-warning::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #F5F5F5;
    border-radius: 10px; }

.scrollbar-warning::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5; }

.scrollbar-warning::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #FF8800; }

.scrollbar-success {
    scrollbar-color: #00c851 #f5f5f5;
}

.scrollbar-success::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #F5F5F5;
    border-radius: 10px; }

.scrollbar-success::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5; }

.scrollbar-success::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #00C851; }

.scrollbar-info {
    scrollbar-color: #33b5e5 #f5f5f5;
}

.scrollbar-info::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #F5F5F5;
    border-radius: 10px; }

.scrollbar-info::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5; }

.scrollbar-info::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #33b5e5; }

.scrollbar-default {
    scrollbar-color: #2bbbad #f5f5f5;
}

.scrollbar-default::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #F5F5F5;
    border-radius: 10px; }

.scrollbar-default::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5; }

.scrollbar-default::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #2BBBAD; }

.scrollbar-secondary {
    scrollbar-color: #aa66cc #f5f5f5;
}

.scrollbar-secondary::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #F5F5F5;
    border-radius: 10px; }

.scrollbar-secondary::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5; }

.scrollbar-secondary::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #aa66cc; }
