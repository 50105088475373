.custom-popup-map .leaflet-popup-content-wrapper {
	background: rgba(0, 0, 0, 0);
	color : black;
	font-size : 12px;
	font-weight: 600;
	line-height:18px;
	border-radius:0px;
	box-shadow : none;
}
.leaflet-popup-tip-container {
	display: none;
}
