
.MuiBadge-colorSecondary {
	background-color : #e0b10b !important;
}
.MuiBadge-anchorOriginBottomRightRectangle {
	bottom: 40% !important;
}
/*
.notActive {
	pointer-events: none;
	cursor: default;
	text-decoration: none;
	color: black;
}*/

.today {
	visibility: hidden;
}
.today:after {
	visibility: visible;
	content: 'OGGI';
}
/*Hide/Show Gis Picker*/
.sidebar {
	height: 100%;
	width: 1.5rem;
	position: fixed;
	z-index: 1;
	top: 0;
	left: 0;
	background-color: #eee;
	display: flex;
	align-items: center;
	transition: 0.5s;
	overflow-x: hidden;
	padding-top: 60px;
	white-space: nowrap;
}

.sidebar a {
	padding: 8px 8px 8px 32px;
	text-decoration: none;
	font-size: 25px;
	color: #818181;
	display: block;
}

.sidebar a:hover {
	color: #f1f1f1;
}

main .sidebar {
	position: absolute;
	top: 0;
	right: 25px;
	font-size: 36px;
	margin-left: 50px;
}

.material-icons,
.icon-text {
	vertical-align: middle;
}

.material-icons {
	padding-bottom: 3px;
	margin-right: 30px;
}

#main {
	padding: 16px;
	transition: margin-left 0.5s;
}

@media screen and (max-height: 4rem) {
	.sidebar {
		padding-top: 15px;
	}
	.sidebar a {
		font-size: 18px;
	}
}
.goToValidation:not(:hover) {
	animation: pulse 2s infinite;
	opacity: 0.3 !important;
}
