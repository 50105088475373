.custom-popup .leaflet-popup-content-wrapper {
	background:#2c3e50d4 ;
	color:#fff;
	font-size:12px;
	line-height:18px;
	}
  .custom-popup .leaflet-popup-content-wrapper a {
	color:rgba(255,255,255,0.5);
	}
	
  .custom-popup .leaflet-popup-tip-container {
/* 	width:30px;
	height:15px; */
	}
  .custom-popup .leaflet-popup-tip {
/* 	border-left:15px solid transparent;
	border-right:15px solid transparent;
	border-top:15px solid #2c3e50d4; */
	background:#2c3e50d4 ;
	}

	.custom-popup .leaflet-control-layers-toggle {/* 
		background-image: url(images/layers.png); */
		width: 50px;
		height: 50px;
	}

	.custom-popup .leaflet-control-layers label {
		margin:0.5rem 1rem ;
	}
	.custom-popup .leaflet-control-layers label > div > .leaflet-control-layers-selector{
		margin-right: 2rem;
	}

	.custom-popup .leaflet-control-layers label > div{
/* 		padding:1rem 0;
 */	}
	.custom-popup .leaflet-control-layers label > div > span{
		font-size: 1rem;
	}

	.custom-popup .leaflet-control-layers label :hover{
/* 		background:#2c3e5024
 */	}
 

 
