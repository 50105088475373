
  
  table.calendar {
	border-collapse: collapse;
	  width: 100%
  }
  
  table.calendar thead {
	background-color: #5A5A5A;
	color: white;
	margin-bottom: 3px;
	border-bottom: 2px solid white
  }

  
  table.calendar thead th {
	font-weight: normal;
	padding: 10px 3px;
  }
  
  table.calendar thead th.bolder {
  }
  
  table.calendar tbody {
	font-size: 0.8em;
  }
  
  table.calendar td {
	text-align: center;
	padding: 8px;
	cursor: pointer;
	border: 1px solid rgba(185, 185, 185, 0.13);
	background-color: white;
	min-width: 15px;
  }
  
  table.calendar tr:last-child td {
	border-bottom: none;
  }
  
  table.calendar td.month-name {
	font-weight: bold;
	text-align: left;
	cursor: default;
	border-left: none;
  }
  
  table.calendar td.prev-month,
  table.calendar td.next-month {
	color: transparent;
	cursor: default;
	pointer-events: none;
	background-color: #e3e3e37a;
  }
  
  table.calendar td.week-separator {
	pointer-events: none;
	padding: 0;
	width: 8px;
	min-width: 0;
	background-color: #e3e3e37a;
  }
  
  table.calendar td.bolder {
  }
  
  /* Single selected day */
  table.calendar td.selected {
	background-color: #20468c;
	color: white;
	font-weight: bold;
  }
  
  /* Selected range */
  table.calendar td.range {
	background-color: #20468c69;
	font-weight: bold;
	color: white;
  }
  
  table.calendar td.range-left {
	border-top-left-radius: 15px;
	border-bottom-left-radius: 15px;
	overflow: hidden;
	background: #20468c;
  }
  
  table.calendar td.range-right {
	border-top-right-radius: 15px;
	border-bottom-right-radius: 15px;
	overflow: hidden;
	background: #20468c;
  }
  
  div.calendar-controls {
	margin: 5px auto;
	display: table;
	font-size: 25px;
	line-height: 35px;
  }
  
  div.calendar-controls div {
	display: inline;
  }
  
  div.calendar-controls .current-year {
	margin: 0 30px;
  }
  
  div.calendar-controls .control {
	font-weight: bolder;
	color: #323232;
	font-size: 0.8em;
	cursor: pointer;
  }
  
  div.calendar-controls .today {
	position: relative;
	right: 15px;
	line-height: 35px;
	font-size: 0.6em;
	text-transform: uppercase;
  }

  .locked {
	  background-color: hsla(99, 54%, 63%, 0.32) !important;
  }
  .future {
	  pointer-events: none !important;
	 	opacity: 0.2 !important;
  }
  .notSelectable{
	  pointer-events: none !important;
	  opacity: 0.2 !important;
  }
  
