body {
    margin: 0px;
}

.info {
    font: 14px/16px Arial, Helvetica, sans-serif;
    background: white;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
}

.info h4 {
    margin: 0 0 3px;
    color: #777;
}

@media (max-width: 1400px) {
	.legend {
		width:100px;
		font-size: 8px;
	}
}
.legend {
    text-align: left;
    color: #555;
}

.legend i {
    width: 18px;
    height: 18px;
    float: left;
    margin-right: 8px;
    opacity: 0.7;
}

.prettyLegendContainer {
    height: auto;
    padding: 10px;
}


.tooltipTest {
    position: relative;

}

.tooltipTest .tooltiptextTest {
    visibility: hidden;
    width:4vw;
    background-color: #6793ba;
    opacity: 0.8;
    text-align: center;

    z-index: 1;

    border-radius: 15px;
    padding: 10px 15px 10px 15px;
    position: relative;
    margin-left: -3vw;
    font-weight: 900;
}
.tooltipTest .tickertextTest {

    width: 4vw;
    opacity: 0.8;
    text-align: center;

    z-index: 1;
    color: #1B2732;
    border-radius: 15px;

    padding-left: 1vw;
    position: absolute;
    /*margin-left: 1vw;*/
    margin-top: -0.8vh;
    font-weight: 900;
}
.prettyLegendBodyContainer{
    height: 30vh;
    cursor: pointer;
}
@media (max-width: 1366px) {
    .tooltipTest .tickertextTest {
        font-size: 10px;
    }
}
@media (max-width: 768px) {
    .prettyLegendBodyContainer{
        height: 30vh;
    }
}

.tooltipTest:hover .tooltiptextTest {
    visibility: visible;
}
