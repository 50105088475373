a {
	display: table !important;;
}
a span {
	display: table-cell !important;
	vertical-align: middle !important;
	text-align: center !important;
}

a span .MuiBadge-colorSecondary {
	background-color : yellow !important;
	transform: scale(1) translate(50%, 200%);
}

a span .MuiBadge-colorPrimary {
	background-color : orange !important;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
	font-weight: 900;
	border-color: rgb(43, 121, 67) !important;
	border-radius: 15px;
	border-width: 3px;
}

.card-header > div{
	cursor: pointer;
}
.playButton {
	height: 100px;
	width: 100px
}
