.inputSelectNotEmpty {
    background: rgba(0, 0, 0, 0.09);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.inputSelect>.MuiInputBase-input {
    height: auto !important;
}
.pointerCursor {
    cursor: pointer !important;
}
